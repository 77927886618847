import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Node-RED_Tutorials/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Node-RED on Android",
  "path": "/Advanced_User/Node-RED_on_Android/",
  "dateChanged": "2019-08-20",
  "author": "Mike Polinowski",
  "excerpt": "Termux now allows you to install Node-RED directly on your Android phone or tablet - turning it into the a powerful smarthome gateway.",
  "image": "./AU_SearchThumb_Android.png",
  "social": "/images/Search/AU_SearchThumb_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_NodeRED_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Node-RED on Android' dateChanged='2019-08-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='Termux now allows you to install Node-RED directly on your Android phone or tablet - turning it into the a powerful smarthome gateway.' image='/images/Search/AU_SearchThumb_Node-RED.png' twitter='/images/Search/AU_SearchThumb_Node-RED.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Node-RED_auf_Android/' locationFR='/fr/Advanced_User/Node-RED_on_Android/' crumbLabel="Node-RED" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "node-red-on-android",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#node-red-on-android",
        "aria-label": "node red on android permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED on Android`}</h1>
    <p>{`We already published a couple of articles showing you how to interact with your INSTAR HD or Full HD camera using `}<a parentName="p" {...{
        "href": "https://nodered.org"
      }}>{`Node-RED`}</a>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Linux/Node-RED/"
        }}>{`Node-RED on Debian Linux`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_MQTT/"
        }}>{`Node-RED and MQTT`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_SQL-Logging/"
        }}>{`Node-RED and SQL logging`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/FHEM_House_Automation/"
        }}>{`Node-RED and the INSTAR MQTT Server`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><a parentName="p" {...{
        "href": "https://termux.com"
      }}>{`Termux`}</a>{` now allows you to install `}<a parentName="p" {...{
        "href": "https://nodered.org"
      }}>{`Node-RED`}</a>{` directly on your Android phone or tablet - turning it into the a powerful smarthome gateway.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/e1596/Node-RED_on_Android_01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHjZNElB//EABcQAAMBAAAAAAAAAAAAAAAAAAABESD/2gAIAQEAAQUCcHM//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRAAAgMBAAAAAAAAAAAAAAAAECEAAREx/9oACAEBAAE/IcO1sYkUP//aAAwDAQACAAMAAAAQEw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAADAAEFAAAAAAAAAAAAAAAAARFRITFBYXH/2gAIAQEAAT8Q4PWEO1K+hXkaS3t9JQ0q9D//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/e4706/Node-RED_on_Android_01.avif 230w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/d1af7/Node-RED_on_Android_01.avif 460w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/7f308/Node-RED_on_Android_01.avif 920w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/e1c99/Node-RED_on_Android_01.avif 1380w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/76ea5/Node-RED_on_Android_01.avif 1840w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/95b77/Node-RED_on_Android_01.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/a0b58/Node-RED_on_Android_01.webp 230w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/bc10c/Node-RED_on_Android_01.webp 460w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/966d8/Node-RED_on_Android_01.webp 920w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/445df/Node-RED_on_Android_01.webp 1380w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/78de1/Node-RED_on_Android_01.webp 1840w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/8df42/Node-RED_on_Android_01.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/e83b4/Node-RED_on_Android_01.jpg 230w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/e41a8/Node-RED_on_Android_01.jpg 460w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/4d836/Node-RED_on_Android_01.jpg 920w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/89b2d/Node-RED_on_Android_01.jpg 1380w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/5726f/Node-RED_on_Android_01.jpg 1840w", "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/e1596/Node-RED_on_Android_01.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cf69dc46ddb9461702e7bb21b3a86a8b/4d836/Node-RED_on_Android_01.jpg",
              "alt": "INSTAR MQTT on Android",
              "title": "INSTAR MQTT on Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Start by installing `}<a parentName="p" {...{
        "href": "https://termux.com"
      }}>{`Termux`}</a>{` on your Android device and start the app. Make sure that everything is up-to-date by running `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apt update && apt upgrade`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d88280984fbe43a40a175a689755c50/e1596/Node-RED_on_Android_02.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHFqaBRg//EABUQAQEAAAAAAAAAAAAAAAAAAAEg/9oACAEBAAEFAmv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAXEAADAQAAAAAAAAAAAAAAAAAAECER/9oACAEBAAE/IZNcX//aAAwDAQACAAMAAAAQkw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhgVFx/9oACAEBAAE/EJ6AfM1eB5q/cgHbf3SshXm//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d88280984fbe43a40a175a689755c50/e4706/Node-RED_on_Android_02.avif 230w", "/en/static/4d88280984fbe43a40a175a689755c50/d1af7/Node-RED_on_Android_02.avif 460w", "/en/static/4d88280984fbe43a40a175a689755c50/7f308/Node-RED_on_Android_02.avif 920w", "/en/static/4d88280984fbe43a40a175a689755c50/e1c99/Node-RED_on_Android_02.avif 1380w", "/en/static/4d88280984fbe43a40a175a689755c50/76ea5/Node-RED_on_Android_02.avif 1840w", "/en/static/4d88280984fbe43a40a175a689755c50/95b77/Node-RED_on_Android_02.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d88280984fbe43a40a175a689755c50/a0b58/Node-RED_on_Android_02.webp 230w", "/en/static/4d88280984fbe43a40a175a689755c50/bc10c/Node-RED_on_Android_02.webp 460w", "/en/static/4d88280984fbe43a40a175a689755c50/966d8/Node-RED_on_Android_02.webp 920w", "/en/static/4d88280984fbe43a40a175a689755c50/445df/Node-RED_on_Android_02.webp 1380w", "/en/static/4d88280984fbe43a40a175a689755c50/78de1/Node-RED_on_Android_02.webp 1840w", "/en/static/4d88280984fbe43a40a175a689755c50/8df42/Node-RED_on_Android_02.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d88280984fbe43a40a175a689755c50/e83b4/Node-RED_on_Android_02.jpg 230w", "/en/static/4d88280984fbe43a40a175a689755c50/e41a8/Node-RED_on_Android_02.jpg 460w", "/en/static/4d88280984fbe43a40a175a689755c50/4d836/Node-RED_on_Android_02.jpg 920w", "/en/static/4d88280984fbe43a40a175a689755c50/89b2d/Node-RED_on_Android_02.jpg 1380w", "/en/static/4d88280984fbe43a40a175a689755c50/5726f/Node-RED_on_Android_02.jpg 1840w", "/en/static/4d88280984fbe43a40a175a689755c50/e1596/Node-RED_on_Android_02.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d88280984fbe43a40a175a689755c50/4d836/Node-RED_on_Android_02.jpg",
              "alt": "INSTAR MQTT on Android",
              "title": "INSTAR MQTT on Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://nodered.org"
      }}>{`Node-RED`}</a>{` runs in `}<a parentName="p" {...{
        "href": "https://nodejs.org/"
      }}>{`Node.js`}</a>{` which we can now install on our Android device by running the following command: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apt install coreutils nano nodejs`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/254572dbf49b9dd9dd47221b694f3a24/e1596/Node-RED_on_Android_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHi5tElB//EABUQAQEAAAAAAAAAAAAAAAAAACAx/9oACAEBAAEFAqv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAZEAACAwEAAAAAAAAAAAAAAAAAIQEQETH/2gAIAQEAAT8hYmcVcFX/2gAMAwEAAgADAAAAEBcP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAICAwEAAAAAAAAAAAAAAQARMXEhUZHB/9oACAEBAAE/EEBpoxi4yfgl79j2sdyrRC3if//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/254572dbf49b9dd9dd47221b694f3a24/e4706/Node-RED_on_Android_03.avif 230w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/d1af7/Node-RED_on_Android_03.avif 460w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/7f308/Node-RED_on_Android_03.avif 920w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/e1c99/Node-RED_on_Android_03.avif 1380w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/76ea5/Node-RED_on_Android_03.avif 1840w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/95b77/Node-RED_on_Android_03.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/254572dbf49b9dd9dd47221b694f3a24/a0b58/Node-RED_on_Android_03.webp 230w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/bc10c/Node-RED_on_Android_03.webp 460w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/966d8/Node-RED_on_Android_03.webp 920w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/445df/Node-RED_on_Android_03.webp 1380w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/78de1/Node-RED_on_Android_03.webp 1840w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/8df42/Node-RED_on_Android_03.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/254572dbf49b9dd9dd47221b694f3a24/e83b4/Node-RED_on_Android_03.jpg 230w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/e41a8/Node-RED_on_Android_03.jpg 460w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/4d836/Node-RED_on_Android_03.jpg 920w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/89b2d/Node-RED_on_Android_03.jpg 1380w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/5726f/Node-RED_on_Android_03.jpg 1840w", "/en/static/254572dbf49b9dd9dd47221b694f3a24/e1596/Node-RED_on_Android_03.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/254572dbf49b9dd9dd47221b694f3a24/4d836/Node-RED_on_Android_03.jpg",
              "alt": "INSTAR MQTT on Android",
              "title": "INSTAR MQTT on Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now with `}<a parentName="p" {...{
        "href": "https://nodejs.org/"
      }}>{`Node.js`}</a>{` in place we can install `}<a parentName="p" {...{
        "href": "https://nodered.org"
      }}>{`Node-RED`}</a>{` with the following command: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`npm install -g --unsafe-perm node-red`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a6065449fc015b6823ed7d3baeef27b/e1596/Node-RED_on_Android_04.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAQACBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeJhAmP/xAAXEAADAQAAAAAAAAAAAAAAAAAAAREg/9oACAEBAAEFAnC5/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQIRExUf/aAAgBAQABPyFrbGnGZio//9oADAMBAAIAAwAAABDTD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQACAgMBAAAAAAAAAAAAAAEAESFRMXGRwf/aAAgBAQABPxADhloIk+hK6fYgc2PcqELcT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a6065449fc015b6823ed7d3baeef27b/e4706/Node-RED_on_Android_04.avif 230w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/d1af7/Node-RED_on_Android_04.avif 460w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/7f308/Node-RED_on_Android_04.avif 920w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/e1c99/Node-RED_on_Android_04.avif 1380w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/76ea5/Node-RED_on_Android_04.avif 1840w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/95b77/Node-RED_on_Android_04.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a6065449fc015b6823ed7d3baeef27b/a0b58/Node-RED_on_Android_04.webp 230w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/bc10c/Node-RED_on_Android_04.webp 460w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/966d8/Node-RED_on_Android_04.webp 920w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/445df/Node-RED_on_Android_04.webp 1380w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/78de1/Node-RED_on_Android_04.webp 1840w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/8df42/Node-RED_on_Android_04.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a6065449fc015b6823ed7d3baeef27b/e83b4/Node-RED_on_Android_04.jpg 230w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/e41a8/Node-RED_on_Android_04.jpg 460w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/4d836/Node-RED_on_Android_04.jpg 920w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/89b2d/Node-RED_on_Android_04.jpg 1380w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/5726f/Node-RED_on_Android_04.jpg 1840w", "/en/static/4a6065449fc015b6823ed7d3baeef27b/e1596/Node-RED_on_Android_04.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a6065449fc015b6823ed7d3baeef27b/4d836/Node-RED_on_Android_04.jpg",
              "alt": "INSTAR MQTT on Android",
              "title": "INSTAR MQTT on Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can start up `}<a parentName="p" {...{
        "href": "https://nodered.org"
      }}>{`Node-RED`}</a>{` by typing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node-red`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/550f9fe137bfcc271d650063075fe41c/e1596/Node-RED_on_Android_05.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAgT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAFhXfIvGAf/xAAZEAACAwEAAAAAAAAAAAAAAAAAAgEQFBP/2gAIAQEAAQUC4K8ZEMiEX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAABQZIgIbL/2gAIAQEABj8C3yiKoiqw/8QAGxABAAICAwAAAAAAAAAAAAAAAQAxEFERIdH/2gAIAQEAAT8huAg0+UdMzpEOpWOZ/9oADAMBAAIAAwAAABBTD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAAICAQUAAAAAAAAAAAAAAAERACFxQVFh4fD/2gAIAQEAAT8QKpMtREBB+HEJLHG6oAoRl5j2EKm6M//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/550f9fe137bfcc271d650063075fe41c/e4706/Node-RED_on_Android_05.avif 230w", "/en/static/550f9fe137bfcc271d650063075fe41c/d1af7/Node-RED_on_Android_05.avif 460w", "/en/static/550f9fe137bfcc271d650063075fe41c/7f308/Node-RED_on_Android_05.avif 920w", "/en/static/550f9fe137bfcc271d650063075fe41c/e1c99/Node-RED_on_Android_05.avif 1380w", "/en/static/550f9fe137bfcc271d650063075fe41c/76ea5/Node-RED_on_Android_05.avif 1840w", "/en/static/550f9fe137bfcc271d650063075fe41c/95b77/Node-RED_on_Android_05.avif 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/550f9fe137bfcc271d650063075fe41c/a0b58/Node-RED_on_Android_05.webp 230w", "/en/static/550f9fe137bfcc271d650063075fe41c/bc10c/Node-RED_on_Android_05.webp 460w", "/en/static/550f9fe137bfcc271d650063075fe41c/966d8/Node-RED_on_Android_05.webp 920w", "/en/static/550f9fe137bfcc271d650063075fe41c/445df/Node-RED_on_Android_05.webp 1380w", "/en/static/550f9fe137bfcc271d650063075fe41c/78de1/Node-RED_on_Android_05.webp 1840w", "/en/static/550f9fe137bfcc271d650063075fe41c/8df42/Node-RED_on_Android_05.webp 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/550f9fe137bfcc271d650063075fe41c/e83b4/Node-RED_on_Android_05.jpg 230w", "/en/static/550f9fe137bfcc271d650063075fe41c/e41a8/Node-RED_on_Android_05.jpg 460w", "/en/static/550f9fe137bfcc271d650063075fe41c/4d836/Node-RED_on_Android_05.jpg 920w", "/en/static/550f9fe137bfcc271d650063075fe41c/89b2d/Node-RED_on_Android_05.jpg 1380w", "/en/static/550f9fe137bfcc271d650063075fe41c/5726f/Node-RED_on_Android_05.jpg 1840w", "/en/static/550f9fe137bfcc271d650063075fe41c/e1596/Node-RED_on_Android_05.jpg 2048w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/550f9fe137bfcc271d650063075fe41c/4d836/Node-RED_on_Android_05.jpg",
              "alt": "INSTAR MQTT on Android",
              "title": "INSTAR MQTT on Android",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now start your default Android web browser and open `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:1880`}</code>{` to access the `}<a parentName="p" {...{
        "href": "https://nodered.org"
      }}>{`Node-RED`}</a>{` interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      